<template>
  <div class="market-categories-root">
    <div class="market-categories-listbox">
      <div
        v-for="option in options"
        :key="option.id"
        :class="[
          'market-categories-listbox__item',
          { 'market-categories-listbox__item--is-disabled': option.disabled },
        ]"
        @click="toggleMarketCategory(option)"
      >
        <Checkbox
          :model-value="modelValue[option.id]"
          @update:model-value="toggleMarketCategory(option)"
          :disabled="option.disabled"
        />
        {{ option.label }}
      </div>
    </div>
    <div class="market-categories-offering-by-state">
      <div
        v-for="option in offeringByGameStateArray"
        :key="option.key"
        class="market-categories-offering-by-state__item"
      >
        <div class="market-categories-offering-by-state__item-checkbox-root">
          <Checkbox
            :model-value="option.isOfferedLive"
            @update:model-value="toggleIsOfferedLive(option)"
            :disabled="offeringByStateCheckboxDisabled(option.type, 'live')"
          />
        </div>
        <div class="market-categories-offering-by-state__item-checkbox-root">
          <Checkbox
            :model-value="option.isOfferedPreMatch"
            @update:model-value="toggleIsOfferedPrematch(option)"
            :disabled="offeringByStateCheckboxDisabled(option.type, 'prematch')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { map, find } from 'lodash';
import Checkbox from '@/components/common/Checkbox';
import {
  marketCategoryTypes,
} from '@/services/helpers/subscriptions';

export default {
  components: {
    Checkbox,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    offeringByGameState: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
    'update:offeringByGameState': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const offeringByGameStateArray = computed(() => map(props.offeringByGameState, (value, key) => ({ key, ...value })));
    const offeringByStateCheckboxDisabled = ((type, state) => {
      if (type === marketCategoryTypes.CORE_AND_DERIVATIVES) return false;
      return (
        !props.modelValue[type]
      || find(props.options, { id: type }).disabled)
      || (state === 'prematch' && !props.offeringByGameState.core.isOfferedPreMatch)
      || (state === 'live' && !props.offeringByGameState.core.isOfferedLive);
    });
    const toggleMarketCategory = (option) => {
      if (option.disabled) return;
      emit('update:modelValue', {
        ...props.modelValue,
        [option.id]: !props.modelValue[option.id],
      });
      // If the market category is enabled, enable the offering by game state
      // If the market category is disabled, disable the offering by game state
      emit('update:offeringByGameState', {
        ...find(offeringByGameStateArray.value, { type: option.id }),
        isOfferedLive: !props.modelValue[option.id],
        isOfferedPreMatch: !props.modelValue[option.id],
      });
    };

    const toggleIsOfferedLive = (option) => {
      emit('update:offeringByGameState', {
        ...option,
        isOfferedLive: !option.isOfferedLive,
      });
    };

    const toggleIsOfferedPrematch = (option) => {
      emit('update:offeringByGameState', {
        ...option,
        isOfferedPreMatch: !option.isOfferedPreMatch,
      });
    };

    return {
      offeringByGameStateArray,
      toggleMarketCategory,
      offeringByStateCheckboxDisabled,
      toggleIsOfferedLive,
      toggleIsOfferedPrematch,
    };
  },
};
</script>

<style lang="scss">
.market-categories-root {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;

  .market-categories-listbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    gap: 4px;

    &__item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #191414;
      padding: 8px;
      cursor: pointer;
      user-select: none;

      &--is-disabled {
        cursor: default;
      }
    }
  }
  .market-categories-offering-by-state {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    &__item {
      width: 100%;
      display: flex;
      gap: 16px;
      padding: 8px 0;

      &-checkbox-root {
        display: flex;
        justify-content: center;
        &:nth-child(1) {
          width: auto;
        }
        &:nth-child(2) {
          width: 100%;
        }
      }
    }
  }
}
</style>
